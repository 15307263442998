import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { Link, NavLink } from "react-router-dom";
import { Connect } from "./index";
import logov2 from '../assets/logo_header.png';

export function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const bottomBorderColor = useColorModeValue("#dfdfdf", "#1e1e1e");

  return (
    <Box>
      <Flex
        bg={"transparent"}
        p={{ base: "5", md: "2", lg: "4" }}
        borderBottomWidth={"0.01em"}
        borderBottomColor={bottomBorderColor}
        minH={"7vh"}
        px={{ base: 2, md: "3%" }}
        align={"center"}
      >
        <Flex flex={2} alignItems={"left"}>
          <Link to={"/"}>
            <img src={logov2} alt="Logo" style={{ maxHeight: '50px', maxWidth: '100%'}} />
          </Link>
        </Flex>
        <Flex
          flex={{ base: 6, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"solid"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          display={{ base: "none", md: "flex" }}
          flex={{ base: 0, md: 2 }}
          ml={10}
          alignItems={"left"}
        >
          <DesktopNav />
        </Flex>
        <Stack
          flex={6}
          justify={"flex-end"}
          direction={"row"}
          alignItems={"center"}
        >
          <Connect />
        </Stack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const activeStyle = {
  fontWeight: "bold",
  fontSize: "0.8em",
};

const inactiveStyle = {
  fontSize: "0.8em",
};

const DesktopNav = () => {
  const color = useColorModeValue("#f3f6fc", "#f3f6fc");

  const activeStyleColor = useColorModeValue("#1e1e1e", "#dfdfdf");
  const inactiveStyleColor = useColorModeValue("#505050", "#707070");

  return (
    <Stack direction={"row"} spacing={2}>
      {NAV_ITEMS.map((navItem) => (
        <Box
          key={navItem.label}
          alignSelf={"center"}
          _hover={{
            bg: color,
            transitionDuration: "0.3s",
          }}
          borderRadius={"15px"}
          px={3}
          py={1}
        >
          <NavLink
            to={navItem.href ?? "#"}
            style={({ isActive }: any) =>
              isActive
                ? { ...activeStyle, color: activeStyleColor }
                : { ...inactiveStyle, color: inactiveStyleColor }
            }
          >
            {navItem.label}
          </NavLink>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  const bottomBorderColor = useColorModeValue("#dfdfdf", "#1e1e1e");

  return (
    <Stack
      bg={useColorModeValue("none", "none")}
      p={4}
      display={{ md: "none" }}
      borderBottomWidth={"0.01em"}
      color={bottomBorderColor}
    >
      <Connect />
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        px={4}
        as={NavLink}
        to={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children && children.map((child) => <>{child.label}</>)}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Clean",
    href: "/",
  },
  {
    label: "Transactions",
    href: "transactions",
  },
];
