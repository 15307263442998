import { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Image,
  Skeleton,
  Spacer,
  Stack,
  Text,
  VStack,
  useCheckboxGroup,
  useColorModeValue,
  useToast,
  Spinner,
  getToken,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";

import { useAccount, useSendTransaction } from "wagmi";
import {
  format,
  useGetTokenInfo,
  useGetTradeInfo,
  useTokenData,
  useGetOdosAPI,
  useGetTokenApproval,
} from "../hooks";
import { Token } from "../utils/types";
import { MaxAllowanceTransferAmount } from "@uniswap/permit2-sdk";
// old rainbow v1
import { useEthersSigner, useEthersProvider } from "../utils/ethers";
//new rainbow v2
// import { getEthersProvider, getEthersSigner} from '../utils/ethers';
import { Contract, providers } from "ethers";
import erc20Abi from "../utils/erc20ABI.json";
import { AccordionAnalytics, Faq, Spin } from "../components";

import { ChainContext } from "../context/ChainContext";

import chainConfig, { supportedChainTemp } from "../config";
import { FaAddressBook } from "react-icons/fa";

export const Swap = () => {
  const getTokenInfo = useGetTokenInfo();
  const getTokenApproval = useGetTokenApproval();
  const { getTradeInfo, loading: loading1 } = useGetTradeInfo();
  const { getTradeReferral } = useGetOdosAPI();

  const { selectedChain } = useContext(ChainContext);

  const { address } = useAccount();

  const { sendTransactionAsync, isSuccess } = useSendTransaction();

  const signer = useEthersSigner();

  // const signer = getEthersSigner();

  // const provider = getEthersProvider();

  const provider = useEthersProvider();

  const [tokens, setTokens] = useState<any[]>();

  const [selected, setSelect] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  const [cleaned, setCleaned] = useState<boolean>(false);

  const [approved, setApproved] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  const [isApproving, setIsApproving] = useState(false);

  const [symbolToApprove, setSymbolToApprove] = useState<string[]>();

  const [slippage, setSlippage] = useState("0.5");

  const [tradeInfo, setTradeInfo] = useState();

  const [checkedTokens, setCheckedTokens] = useState<(string | number)[]>();

  const [assemble, setAssemble] = useState<any>();

  const borderColor = useColorModeValue("#dfdfdf", "#1e1e1e");

  const fontColor = useColorModeValue("blackAlpha.500", "whiteAlpha.700");

  const [tradeInfoOdosPath, setTradeInfoOdosPath] = useState<any>();

  const [tokensAllowance, setTokensAllowance] = useState<any>();

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const maxAllowedChecks = 6;

  const { value, setValue, getCheckboxProps } = useCheckboxGroup({
    onChange: (values) => {
      if (values.length <= maxAllowedChecks) {
        setCheckedTokens(values);
      } else {
      }
    },
  });
  useEffect(() => {
    setValue([]);
  }, [selectedChain]);

  const selectedTokens = useTokenData(value);

  const handleSlippageChange = (value: string) => {
    // Handle state change, validation, or any other logic here
    setSlippage(value);
  };

  const SPENDER_ADDRESS =
    chainConfig.chains[selectedChain.chainId].spenderAddress;

  useEffect(() => {
    if (tokens !== undefined && tokens.length > 0) {
      const tokensToApprove = selectedTokens.filter((token) => {
        return token.allowance === 0;
      });

      const symbolsToApprove: string[] = tokensToApprove.map((token) => {
        return tokens.find((t) => t.token_address === token.tokenAddress)
          ?.symbol;
      });
      setSymbolToApprove(symbolsToApprove);
    }
  }, [value]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getTokenInfo(address || "", SPENDER_ADDRESS as string).then(
        ([result, err]) => {
          setTokens(result);
          setIsLoading(false);
          if (err != null) {
            toast({
              title: "Error",
              description: err.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        }
      );
    })();
  }, [address, selectedChain]);


  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      setLoading(true);
      const tradeInfo = await getTradeInfo(
        value,
        address || "",
        slippage,
        provider,
        controller.signal
      );

      if (tradeInfo) {
        if (tradeInfo.error) {
          console.log("errorrrrr in trade info : ", tradeInfo.error);
          setError(true);
          setLoading(true);
          toast({
            title: "Token not supported",
            description: `Selected token is not supported`,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setError(false);
          setTradeInfoOdosPath(tradeInfo.odosPathViz);
          setTradeInfo(tradeInfo.tradeInfo);
          setLoading(false);
        }
      } else {
        setLoading(true);
        return;
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [slippage, value]);

  useEffect(() => {
    const [tokenAddress, tokenAllowance, tokenBalance] =
      String(value).split("|");
    getTokenApproval(tokenAddress, address, SPENDER_ADDRESS as string).then(
      (result) => {
        setTokensAllowance(result);
 
        // Check if result?.allowance is not equal to '0'
        if (result?.allowance != "0") {
          setApproved(true);
        } else {
          setApproved(false);
        }
      }
    );
  }, [value]);

  const approve = async (tokensApproving: Token[]) => {
    try {
      for (const token of tokensApproving) {
        setIsApproving(true);
        const tokenSymbol = tokens?.find(
          (t) => t.token_address === token.tokenAddress
        )?.symbol;
        setSymbolToApprove([tokenSymbol as string]);

        const tokenAddress = token.tokenAddress;
        const tokenAllowance = token.allowance;
        try {
          if (tokenAllowance === 0) {
            const erc20 = new Contract(tokenAddress, erc20Abi, signer);
            const approveTx = await erc20.approve(
              SPENDER_ADDRESS,
              MaxAllowanceTransferAmount
            );

            toast({
              title: `Approving ${tokenSymbol} token...`,
              status: "info",
              duration: 7000,
              isClosable: true,
              position: "top-right",
            });

            const receipt = await approveTx.wait();
            if (receipt.status !== 1) throw new Error("Transaction failed");
          } else {
            continue;
          }
        } catch (error) {
          console.error(
            `Failed to process permit for token ${tokenAddress}: `,
            error
          );
          toast({
            title: `${tokenSymbol} token failed to approve. Try again.`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          setApproved(false);
          window.location.reload();

          return;
        }
        // approvedTokensStockage.push(`${token.tokenAddress}|${token.allowance}|${token.balance}`);
        // localStorage.setItem('approvedTokens', JSON.stringify(approvedTokensStockage));
      }
    } catch (error) {
      console.error(
        "An error occurred during batch permit processing: ",
        error
      );
    }

    setIsApproving(false);
    setApproved(true);
    toast({
      title: `${symbolToApprove?.[0]} approved`,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const clean = async (tokens: any[]) => {
    const inputTokens = [];
    setCleaned(true);
    for (const token of tokens) {
      try {
        const [tokenAddress, tokenAllowance, tokenBalance] = token.split("|");
        // let approval = await getTokenApproval(tokenAddress, address, SPENDER_ADDRESS as string);
        // console.log("approval :",approval,"\n Token balance : ", tokenBalance);
        inputTokens.push({
          tokenAddress: tokenAddress,
          amount: tokenBalance,
        });
      } catch (error) {
        const [tokenAddress] = token.split("|");
        console.error(
          `Failed to process permit for token ${tokenAddress}: `,
          error
        );
      }
    }
    await getTradeReferral(value, address, slippage, provider).then(
      async (assembleWithReferral) => {
        setAssemble(assembleWithReferral);
        let tx = assembleWithReferral?.transaction;

        try {
          await sendTransactionAsync(tx);
          toast({
            title: "Cleaning...",
            status: "loading",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } catch (error) {
          setCleaned(false);
          console.error("Error during transaction:", error);
          if (assemble?.simulation?.isSuccess === false) {
            try {
              const errorMessage = JSON.parse(
                assemble.simulation.simulationError.errorMessage
              ).message;

              console.error("Error during transaction:", errorMessage);
            } catch (parseError) {}
          }
          toast({
            title: "Transaction failed",
            status: "error",
            duration: 100000,
            isClosable: true,
            position: "top-right",
          });
          setError(true);
          console.log(isSuccess);
        }
      }
    );
  };

  const cleanToken = async () => {
    await clean(value);
    const result = await getTokenInfo(address || "", SPENDER_ADDRESS as string);

    if (isSuccess) {
      setTimeout(() => {
        toast({
          title: "Token cleaned successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }, 6000);
    } else {
      console.log(result);
      setCleaned(false);
      setTokens(result[0]);
      window.location.reload();
    }
  };

  return (
    <Stack>
      <Flex w={"100%"}>
        <Heading as="h1" noOfLines={2} mt={"50px"} w={"100%"}>
          <Text
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            bgClip="text"
            fontSize="6xl"
            fontWeight="extrabold"
            textAlign="center"
          >
            Select your dust
          </Text>
        </Heading>
      </Flex>
      <Flex
        w={"100%"}
        justifyContent={{ base: "mi", md: "center" }}
        style={{
          filter: !supportedChainTemp.includes(selectedChain.chainId)
            ? "blur(1px)"
            : "none",
          pointerEvents: !supportedChainTemp.includes(selectedChain.chainId)
            ? "none"
            : "auto",
        }}
      >
        <VStack
          w={{ base: "100%", md: "70%", lg: "50%" }}
          mt="2em"
          justifyContent={{ base: "mi", md: "center" }}
          alignItems={"start"}
        >
          <Box flex={6} w={"100%"}>
            <Stack
              spacing={2}
              borderWidth={"0.1em"}
              borderColor={borderColor}
              borderRadius={"25px"}
              p={3}
            >
              <Heading p={3} size={"sm"}>
                Tokens to clean
                <Text fontSize={"sm"} color={fontColor}>
                  {value.length} / 6
                </Text>
              </Heading>
              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Spinner
                    thickness="1px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="black"
                    size="xl"
                    margin={"auto"}
                  />
                </Box>
              ) : (
                <>
                  {tokens == null || tokens == undefined ? (
                    <Stack justifyContent={"center"} w={"full"}>
                      <Skeleton height="7vh" borderRadius={"15px"} />
                      <Skeleton height="7vh" borderRadius={"15px"} />
                      <Skeleton height="7vh" borderRadius={"15px"} />
                      <Skeleton height="7vh" borderRadius={"15px"} />
                      <Skeleton height="7vh" borderRadius={"15px"} />
                    </Stack>
                  ) : tokens.length === 0 ? (
                    <Flex justifyContent={"center"} py={9} w={"full"}>
                      <Text fontSize={"sm"} color={fontColor}>
                        No tokens found
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      <VStack
                        maxH={"50vh"}
                        overflowY={"scroll"}
                        alignItems={"start"}
                      >
                        {tokens.map((token, i) => (
                          <HStack
                            borderWidth={"0em"}
                            borderColor={borderColor}
                            borderRadius={"15px"}
                            bgColor={"#f1f0f0"}
                            w={"full"}
                            p={3}
                            h={"7rem"}
                            key={i}
                          >
                            <Checkbox
                              w={"100"}
                              size={"lg"}
                              borderColor={borderColor}
                              disabled={
                                !token.isListed ||
                                (value.length === maxAllowedChecks &&
                                !checkedTokens?.includes(
                                  `${token.token_address}|${token.allowance}|${token.balance}`
                                )
                                )
                              }
                              value={token.token_address}
                              {...getCheckboxProps({
                                value: `${token.token_address}|${token.allowance}|${token.balance}`,
                              })}
                              isChecked={checkedTokens?.includes(
                                `${token.token_address}|${token.allowance}|${token.balance}`
                              )}
                            >
                              <Text
                                fontSize={"1xl"}
                                fontWeight={"bold"}
                                ml={"1em"}
                              >
                                {token.isListed ? (<Text>{token.symbol}</Text>):(<Text>{token.symbol} (Listed soon)</Text>)}
                              </Text>
                            </Checkbox>
                            <Spacer />

                            <VStack alignItems={"flex-end"} gap={0}>
                              <Text fontSize={"1xl"}>
                                {format(
                                  token.balance_formatted,
                                  token.usd_value
                                )[1] + ` $`}
                              </Text>
                              <Text
                                fontSize={"md"}
                                color={fontColor}
                                p={0}
                                m={0}
                              >
                                {
                                  format(
                                    token.balance_formatted,
                                    token.usd_value
                                  )[0]
                                }
                              </Text>
                            </VStack>

                            {token.logo ? (
                              <Image
                                src={token.logo}
                                boxSize={"3rem"}
                                borderRadius={"full"}
                                alt={token.symbol}
                              />
                            ) : (
                              <Text fontSize={"sm"}>❔</Text>
                            )}
                          </HStack>
                        ))}
                      </VStack>
                      <HStack w={"full"} justifyContent={"center"}>
                        <Button
                          borderRadius={"15px"}
                          variant={"outline"}
                          onClick={() => {
                            const call =
                              selected ||
                              (value.length === tokens.length &&
                                value.length) ||
                              tokens.length > 6
                                ? () => {
                                    setValue([]);
                                    setSelect(false);
                                  }
                                : () => {
                                    setValue(
                                      tokens.map(
                                        (t) =>
                                          `${t.token_address}|${t.allowance}|${t.balance}`
                                      )
                                    );
                                    setSelect(true);
                                  };
                            call();
                          }}
                          fontWeight={"medium"}
                          flex={1}
                          isDisabled={loading}
                          _disabled={{ opacity: "0.7", cursor: "not-allowed" }}
                        >
                          {selected ||
                          value.length === tokens.length ||
                          tokens.length > 6
                            ? "Unselect All"
                            : "Select All"}{" "}
                        </Button>
                        {!approved ? (
                          <Button
                            borderRadius={"15px"}
                            variant={"outline"}
                            onClick={() => approve(selectedTokens)}
                            fontWeight={"medium"}
                            flex={1}
                            isDisabled={
                              value.length === 0 || isApproving || loading
                            }
                            _disabled={{
                              opacity: "0.7",
                              cursor: "not-allowed",
                            }}
                          >
                            {" "}
                            {isApproving ? (
                              <>
                                <Spinner
                                  marginRight={"0.2rem"}
                                  thickness="1px"
                                  speed="1s"
                                  emptyColor="gray.200"
                                  color="black"
                                  size="xs"
                                />
                                {`Approve ${symbolToApprove?.[0] ?? ""}`}
                              </>
                            ) : (
                              `Approve ${symbolToApprove?.[0] ?? ""}`
                            )}
                          </Button>
                        ) : (
                          <Button
                            borderRadius={"10px"}
                            borderColor={borderColor}
                            onClick={cleanToken}
                            fontWeight={"medium"}
                            variant={"outline"}
                            isDisabled={
                              loading || (error ? false : value.length === 0)
                            }
                            flex={1}
                          >
                            {error ? (
                              "Clean"
                            ) : cleaned ? (
                              <>
                                <Spinner
                                  marginRight="0.7rem"
                                  thickness="1px"
                                  speed="1s"
                                  emptyColor="gray.200"
                                  color="black"
                                  size="md"
                                />
                                Cleaning
                              </>
                            ) : isSuccess ? (
                              "Clean"
                            ) : (
                              "Clean"
                            )}
                          </Button>
                        )}
                      </HStack>
                    </>
                  )}
                </>
              )}
              <AccordionAnalytics
                slippage={slippage}
                onSlippageChange={handleSlippageChange}
                tradeInfo={tradeInfo}
                tokenNumber={value.length}
                value={value}
                address={address}
                provider={provider}
                loading={loading}
                tradeInfoOdosPath={tradeInfoOdosPath}
                error={error}
              />
            </Stack>
          </Box>
        </VStack>
      </Flex>
      <Flex w={"100%"} justifyContent={"center"}>
        <Text
          fontSize="md"
          color="#CCC"
          textAlign="center"
          w={{ base: "95vw", md: "60vw", lg: "475px" }}
        >
          CleanSwap will select the best route. <br /> Check transaction
          details!
          <ChevronUpIcon w={6} h={6} />
        </Text>
      </Flex>
      <Flex mt={5} w={'100%'} justifyContent={"center"} >
        <Text 
          bgGradient='linear(to-l, #7928CA, #FF0080)'
          bgClip='text'
          fontSize='md'
          textAlign='center'
          w={{ base: "95vw", md: "60vw", lg: "475px" }}
        >
          Swap here, earn more! Eligible for potential aggregator airdrops with CleanSwap.
        </Text>
      </Flex>
      <Flex mt={5} w={'100%'} justifyContent={"center"} >
        <Text 
          bgGradient='linear(to-l, #f9530b, #ff9005)'
          bgClip='text'
          fontSize='md'
          textAlign='center'
          w={{ base: "95vw", md: "60vw", lg: "475px" }}
        >
          At Cleanswap, we take your security seriously. Our protocol sends tokens directly to aggregators without ever listing or promoting scam tokens. 
        </Text>
      </Flex>
    </Stack>
  );
};
