import { Center,Stack,Heading,Text,Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useAccount } from "wagmi";
import { Navbar, Connect, Footer, Alertbar, Faq } from "./index";
import { color } from "style-value-types";
import logov2 from '../assets/logo_header.png';
import { auto } from "@popperjs/core";

export const Frame = () => {
  const { isConnected } = useAccount();
  return (
    <Box  w={"full"} textAlign="center" fontSize="xl" bgGradient='linear(#fdfdfc, pink.50)'>
      <Alertbar />
      <Navbar />
      <Center>
        <Box w={'70%'} textAlign={'center'}>
          {isConnected ? (
            <Text></Text>
          ) : (
            <Flex w={'100%'} justifyContent={"center"}>
              <Stack spacing={12}>
              <Heading as='h1' mt={20} noOfLines={5} >
                <img src={logov2} alt="Logo" style={{ maxHeight: '350px', maxWidth: '150px', margin:auto  }} />
                <Text
                  
                  bgGradient='linear(to-l, #7928CA, #FF0080)'
                  bgClip='text'
                  fontSize='6xl'
                  fontWeight='extrabold'
                >
                  From Dust to Value
                </Text>
                </Heading>
                <Heading as='h2' size='lg' noOfLines={8}>
                  Streamline Your Wallet with Ease in one transaction
                </Heading>
              </Stack>
            </Flex>
          )
          }
        </Box>
      </Center>
      {isConnected ? (
        <Box >
            <Outlet />
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w={"full"}
            >
            </Flex>
            <Flex w={'100%'} justifyContent={"center"}>
            <Box w={{ base: "100%", md: "70%", lg: "50%" }}>
              <Faq/>
            </Box>          
          </Flex>
        </Box>
      ) : (
        <Box mt={10} >
          <Connect  />
          <Flex w={'100%'} justifyContent={"center"}>
            <Box w={{ base: "100%", md: "70%", lg: "50%" }}>
              <Faq/>
            </Box>          
          </Flex>
        </Box>
      )}

      <Footer />
    </Box>
  );
};
