import { createBrowserRouter } from "react-router-dom";
import { Swap, Transactions } from "./pages";
import { Frame } from "./components";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Frame />,
    children: [
      {
        path: "/",
        element: <Swap />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
    ],
  },
]);

export default router;
