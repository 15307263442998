import { useState, useContext } from "react";
import { ChainContext } from "../context/ChainContext";
import chainConfig from "../config";

export function useGetOdosAPI({
  inputTokens = [],
  address,
}: { inputTokens?: []; address?: string } = {}) {
  const { selectedChain } = useContext(ChainContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const referalCode = chainConfig.chains[selectedChain.chainId].referralCode;

  const getTradeReferral = async (
    tokens?: any,
    address?: any,
    slippage?: string,
    provider?: any
  ): Promise<any> => {
    let inputTokens = [];
    let quote;
    let assemble;

    if (tokens && tokens.length === 0) {
    } else {
      if (tokens !== undefined) {
        for (const token of tokens) {
          if (typeof token === "string") {
            try {
              const [tokenAddress, _, tokenBalance] = token.split("|");
              inputTokens.push({
                amount: tokenBalance,
                tokenAddress: tokenAddress,
              });
            } catch (error) {
              console.error(`Failed to process token: `, error);
            }
          } else if (typeof token === "object") {
            try {
              const tokenAddress = token.tokenAddress;
              const tokenBalance = token.amount;
              inputTokens.push({
                tokenAddress: tokenAddress,
                amount: tokenBalance,
              });
            } catch (error) {
              console.error(`Failed to process token: `, error);
            }
          } else {
            console.log("unknown token type");
          }
        }
      }
    }
    try {
      const quoteRequestBodyReferral = {
        chainId: selectedChain.chainId,
        inputTokens: inputTokens,
        outputTokens: [
          {
            tokenAddress: "0x0000000000000000000000000000000000000000", // checksummed output token address
            proportion: 1,
          },
        ],
        userAddr: address as string, // checksummed user address
        slippageLimitPercent: slippage, // set your slippage limit percentage (1 = 1%)
        referralCode: referalCode,
        compact: true,
      };

      if (inputTokens.length !== 0) {
        const odosQuoteReqRefferal = await fetch(
          "https://api.odos.xyz/sor/quote/v2",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(quoteRequestBodyReferral),
          }
        );
        if (odosQuoteReqRefferal.status === 200) {
          quote = await odosQuoteReqRefferal.json();

          const assembleRequestBody = {
            userAddr: address as string,
            pathId: quote.pathId,
            simulate: true,
          };

          let assembleResponse = await fetch(
            "https://api.odos.xyz/sor/assemble",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(assembleRequestBody),
            }
          );

          if (assembleResponse.status === 200) {
            try {
              assemble = await assembleResponse.json();
              return assemble;
            } catch (error) {
              console.error("Error in Transaction Assembly with odos API:", error);
            }
          } else {
            console.error(
              "Error in Transaction Assembly with odos API:",
              odosQuoteReqRefferal
            );
          }
        }
      }
    } catch (error) {
      console.error("Error in getTradeReferral:", error);
    }
  };
  return { loading, error, getTradeReferral };
}
