export const useTokenData = (value: (string | number)[]) => {

  const tokenData = value.map(token => {
    const [tokenAddress, tokenAllowance, tokenBalance] = (token as string).split('|');
    return {
      tokenAddress,
      allowance: Number(tokenAllowance),
      balance: Number(tokenBalance)
    };
  });
  return tokenData;
}