import { useEffect, useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  Spinner,
  Flex,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  useDisclosure,
  AlertDescription,
  Heading,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { ChainContext } from "../context/ChainContext";
import { useGetTradeInfo } from "../hooks/getTradeInfo";

export function OdosPathViz({
  // inputTokens,
  address,
  slippage,
  provider,
  loading, 
  tradeInfoOdosPath
}: {
  // inputTokens?: any;
  address?: any;
  slippage?: any;
  provider?: any;
  loading?: boolean;
  tradeInfoOdosPath?: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState<any>(null);
  const [err, setErr] = useState<boolean>(false);
  const [odosPathViz, setOdosPathViz] = useState("");

  useEffect(() => {
    if (tradeInfoOdosPath) {
      setOdosPathViz(tradeInfoOdosPath);
    }
  }
  , [tradeInfoOdosPath]);

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Error!</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  function changeFontFamilyOfSVG(
    base64SVG: string,
    newFontFamily: string
  ): string {
    // Convert base64 SVG to a regular SVG string
    const svgString = atob(base64SVG.replace("data:image/svg+xml;base64,", ""));

    /* change font,  but needs to provide new font in base64
        const startSubstring = "font-family: &#x27;";
        const endSubstring = "&#x27;";
        const regexFont = new RegExp(`${startSubstring}(.*?)${endSubstring}`, 'g');
        const svgStringRegEx = svgString.replace(regexFont, `${startSubstring}${newFontFamily}${endSubstring}`);

        const startSubstring2 = "{\n" +
            "      font-family: ";
        const endSubstring2 = ";";
        const regexFont2 = new RegExp(`${startSubstring2}(.*?)${endSubstring2}`, 'g');
        const svgStringRegEx2 = svgStringRegEx.replace(regexFont2, `${startSubstring2}${newFontFamily}${endSubstring2}`);
        console.log(svgStringRegEx2);
        */

    // Create a DOMParser to parse SVG string
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

    // Find all text elements and change their font-family
    const textElements = svgDoc.querySelectorAll("text");
    textElements.forEach((textElement) => {
      textElement.setAttribute("font-family", newFontFamily);
    });

    // Find all rectangles elements and change round
    const rectElements = svgDoc.querySelectorAll("rect");
    rectElements.forEach((rectElement) => {
      rectElement.setAttribute("rx", "30");
    });

    // Find all path elements and change round
    const pathElements = svgDoc.querySelectorAll("path");
    pathElements.forEach((pathElement) => {
      const currentStyle = pathElement.getAttribute("style");
      const newStyle = currentStyle + ";stroke-width: 40;"; // Adding a new CSS property
      pathElement.setAttribute("style", newStyle);
    });

    // find an id and change attribute
    const elementGradient = svgDoc.getElementById("gradient0");
    if (elementGradient) {
      elementGradient.setAttribute("x1", "1000%");
    } else {
    }

    // Serialize the modified SVG back to string
    const modifiedSVGString = new XMLSerializer().serializeToString(svgDoc);
    // console.log(modifiedSVGString);

    // Encode the modified SVG back to base64
    const encodedSVG = btoa(modifiedSVGString);

    // add URI schemas
    const outputSVG = "data:image/svg+xml;base64," + encodedSVG;
    return outputSVG;
  }

  const newImage = changeFontFamilyOfSVG(odosPathViz, "Brush Script MT");
  return (
    <>
      <Button fontWeight={"bold"} onClick={onOpen}>
        Open Route <Search2Icon ml="3" />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent bgGradient="linear(#fdfdfc, pink.50)">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Spinner
                  thickness="1px"
                  speed="1s"
                  emptyColor="gray.200"
                  color="black"
                  size="xl"
                />
              </Flex>
            ) : (err) ? (
              <Box textAlign="center">
                <Heading size={"s"}>Token not supported</Heading>
              </Box>
            ) : odosPathViz === "" ? (
              <Box textAlign="center">
                <Heading size={"s"}>Select a token to clean</Heading>
              </Box>
            ) : (
              <img src={newImage} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              fontWeight={"bold"}
              background={"#fcf0fe"}
              color={"#eb7af8"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
