import { Button } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { supportedChainTemp } from "../config";

export const Connect = ({ variant }: any) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        const supportedChainsOdos = [1, 324, 8453, 137, 10, 34443, 43114, 42161, 56, 250];
        if (chain?.id !== undefined && (!supportedChainsOdos.includes(chain.id) || !supportedChainTemp.includes(chain.id))) {
          chain!.unsupported = true;
        }
  
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    variant={variant}
                    fontWeight={"bold"}
                    onClick={openConnectModal}
                    background={'#fcf0fe'}
                    color={'#eb7af8'}>
                    Connect Wallet
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button
                    variant={"ghost"}
                    colorScheme="red"
                    onClick={openChainModal}
                    type="button"
                    w={"full"}
                  >
                    Coming soon
                  </Button>
                );
              }

              return (
                <div
                  style={{
                    display: "flex",
                    gap: 12,
                  }}
                >
                  <Button
                    variant={"ghost"}
                    onClick={openChainModal}
                    style={{ display: "flex", alignItems: "center" }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 20,
                          height: 20,
                          borderRadius: 999,
                          overflow: "hidden",
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 20, height: 20 }}
                          />
                        )}
                      </div>
                    )}
                  </Button>

                  <Button
                    variant={"ghost"}
                    onClick={openAccountModal}
                    type="button"
                    flex={{ base: 1, md: "none" }}
                  >
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ""}
                  </Button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
