import { useState, useEffect } from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Link,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useGetTxns } from "../hooks";
import { useAccount } from "wagmi";

export const Transactions = () => {
  const { address } = useAccount();

  const borderColor = useColorModeValue("#dfdfdf", "#1e1e1e");
  const bgColor = useColorModeValue("#fff", "#000");
  const fontColor = useColorModeValue("blackAlpha.700", "whiteAlpha.700");

  const [txns, setTxns] = useState<any[]>();

  const toast = useToast();

  const getTxns = useGetTxns();

  useEffect(() => {
    (async () => {
      const [result, err] = await getTxns(address || "");
      if (err != null) {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      setTxns(result);
    })();
  }, []);

  return (
    <Stack
      h={"78vh"}
      alignItems={"center"}
      marginTop={"3rem"}
      justifyContent={"start"}
      paddingBottom={"3rem"}
    >
      <Stack spacing={2} p={3} w={{ base: "95vw", md: "60vw", lg: "475px" }}>
        <Heading p={2} size={"sm"}>
          Transactions
        </Heading>
        {txns == null ? (
          <Stack justifyContent={"center"} w={"full"}>
            <Skeleton height="7vh" borderRadius={"15px"} />
            <Skeleton height="7vh" borderRadius={"15px"} />
            <Skeleton height="7vh" borderRadius={"15px"} />
            <Skeleton height="7vh" borderRadius={"15px"} />
            <Skeleton height="7vh" borderRadius={"15px"} />
          </Stack>
        ) : txns.length === 0 ? (
          <Flex justifyContent={"center"} py={9} w={"full"}>
            <Text fontSize={"sm"} color={fontColor}>
              No transactions found
            </Text>
          </Flex>
        ) : (
          <>
            {txns.map((txn, i) => (
              <Stack
                key={i}
                p={4}
                borderWidth={"0.1em"}
                borderRadius={"xl"}
                borderColor={borderColor}
                bg={bgColor}
                fontSize={"sm"}
                alignItems={"start"}
              >
                <Link
                  href={`https://bscscan.com/tx/` + txn.hash}
                  isExternal
                  w={"full"}
                >
                  <Flex w={"full"} alignItems={"center"}>
                    {txn.hash.substring(0, 6) + "..." + txn.hash.substring(58)}
                    <Spacer />
                    <Text mr={2}>
                      {new Date(txn.block_timestamp).toLocaleString("en-us", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Text>
                    <ExternalLinkIcon />
                  </Flex>
                </Link>
              </Stack>
            ))}
          </>
        )}
      </Stack>
      <Spacer />
    </Stack>
  );
};
