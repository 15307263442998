export const format = (balance: string, usd: string) => {
  const balance_formatted = formatNumber(parseFloat(balance));
  const usd_value = formatNumber(parseFloat(usd));
  
  return [balance_formatted, usd_value];
};

function formatNumber(num: number): string {

  if (num === 0) {
    return "0";
  }

  // Finding precision scale
  let precision = 6; // by default 
  if (num < 0.0001 && num >= 0.0000001) {
      precision = 7;
  } else if (num < 0.0000001) {
      precision = 20;
  }

  let formatted = num.toFixed(precision);
  // Remove Zero 
  formatted = formatted.replace(/(\.\d*?[1-9])0+$/, "$1");
  // Remove separator if no decimal
  formatted = formatted.replace(/\.0+$/, "");

  return formatted;

}