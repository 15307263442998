type ChainConfig = {
  [key: number]: {
    name: string;
    symbol: string;
    network: string;
    spenderAddress: string;
    nativeTokenAddress: string;
    minimumDustInUSD: number;
    referralCode: number | undefined;
  };
};

const chainConfig: { chains: ChainConfig } = {
  chains: {
    1: {
      name: "Ethereum",
      symbol: "ETH",
      network: "mainnet",
      spenderAddress: "0xCf5540fFFCdC3d510B18bFcA6d2b9987b0772559",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD : 2000,
      referralCode: 3978021280,
    },
    56: {
      name: "BNB Smart Chain",
      symbol: "BNB",
      network: "bsc",
      spenderAddress: "0x89b8AA89FDd0507a99d334CBe3C808fAFC7d850E",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD : 200,
      referralCode: 3978021280,
    },
    42161: {
      name: "Arbitrum",
      symbol: "ETH",
      network: "arbitrum",
      spenderAddress: "0xa669e7A0d4b3e4Fa48af2dE86BD4CD7126Be4e13",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD : 200,
      referralCode: 3978021280,
    },
    137: {
      name: "Polygon",
      symbol: "MATIC",
      network: "polygon",
      spenderAddress: "0x4E3288c9ca110bCC82bf38F09A7b425c095d92Bf",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
    43114: {
      name: "Avalanche",
      symbol: "AVAX",
      network: "avalanche",
      spenderAddress: "0x88de50B233052e4Fb783d4F6db78Cc34fEa3e9FC",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
    8453: {
      name: "Base",
      symbol: "ETH",
      network: "base",
      spenderAddress: "0x19cEeAd7105607Cd444F5ad10dd51356436095a1",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD : 200,
      referralCode: 3978021280,
    },
    10: {
      name: "Optimism",
      symbol: "ETH",
      network: "optimism",
      spenderAddress: "0xCa423977156BB05b13A2BA3b76Bc5419E2fE9680",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
    250: {
      name: "Fantom",
      symbol: "FTM",
      network: "fantom",
      spenderAddress: "0xD0c22A5435F4E8E5770C1fAFb5374015FC12F7cD",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
    324: {
      name: "zkSync Era",
      symbol: "ETH",
      network: "zksync-era",
      spenderAddress: "0x4bBa932E9792A2b917D47830C93a9BC79320E4f7",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
    34443: {
      name: "Mode",
      symbol: "ETH",
      network: "mode",
      spenderAddress: "0x7E15EB462cdc67Cf92Af1f7102465a8F8c784874",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
    59144: {
      name: "Linea",
      symbol: "ETH",
      network: "linea",
      spenderAddress: "0x2d8879046f1559E53eb052E949e9544bCB72f414",
      nativeTokenAddress: "0x0000000000000000000000000000000000000000",
      minimumDustInUSD: 200,
      referralCode: 3978021280,
    },
  },
};

export default chainConfig;

export const supportedChainTemp = [56,8453];
