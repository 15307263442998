import { extendTheme } from "@chakra-ui/react";
import "@fontsource/inter";

const theme = extendTheme({
  styles: {
    global: ({ colorMode }: any) => ({
      body: {
        bg: colorMode === "dark" ? "#000" : "white",
        color: colorMode === "dark" ? "white" : "black",
      },
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#444",
      },
    }),
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          bg: "#000",
          borderWidth: "0.1rem",
          borderRadius: "md",
          motionPreset: "slideInBottom",
        },
      },
    },
    Button: {
      baseStyle:{
        borderRadius: '15px',
        fontWeight: 'bold',
        fontSize: '48px',
        borderStyle:'solid',
      }
    }
  },
});

export default theme;
