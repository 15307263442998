import { useContext } from "react";
import { ChainContext } from "../context/ChainContext";

export const useGetTxns = () => {
  const { selectedChain } = useContext(ChainContext);

  const getTxns = async (address: string) => {
    try {
      const response = await fetch(
        "https://deep-index.moralis.io/api/v2.2/" +
          address +
          `/?chain=${selectedChain.chainName}&limit=50`,
        {
          headers: {
            "X-API-Key": process.env.REACT_APP_MORALIS_API_KEY as string,
          },
        }
      );

      const json = await response.json();

      const filtered = json.result.filter(
        (tx: any) =>
          tx.to_address === "0x89b8aa89fdd0507a99d334cbe3c808fafc7d850e"
      );

      return [filtered, null];
    } catch (err) {
      console.log(err);
      return [[], err];
    }
  };
  return getTxns;
};
