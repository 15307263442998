import { useContext, useEffect, useState } from "react";
import { BigNumber, Contract, ethers } from "ethers";
import erc20Abi from "../utils/erc20ABI.json";
import { useFetchTokenPrice } from "./getTokenInfo";
import { MaxAllowanceTransferAmount } from "@uniswap/permit2-sdk";
import { ChainContext } from "../context/ChainContext";
import chainConfig from "../config";
// import { useGetOdosAPI } from "../hooks/getOdosAPI";
// import { useEthersSigner, useEthersProvider } from "../utils/ethers";

export interface TradeInfo {
  gasEstimateValue: number;
  outAmounts: number;
  slippage: number;
  gasApproval: number;
  feePrice?: number;
}
export const initialTradeInfo: TradeInfo = {
  gasEstimateValue: 0,
  outAmounts: 0,
  slippage: 0.5,
  gasApproval: 0,
};

export function useGetTradeInfo({
  inputTokens = [],
  address,

}: { inputTokens?: []; address?: string } = {}) {
  const { selectedChain } = useContext(ChainContext);
  const estimateGasForApproval = useEstimeGasForApproval();
  const estimateGasForSwap = useEstimeGasForSwap();
  const [error, setError] = useState<any>(null);
  const [err, setErr] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getTradeInfo = async (
    tokens?: any[],
    address?: any,
    slippage?: string,
    provider?: any,
    signal?: AbortSignal 
  ): Promise<any> => {
    let inputTokens = [];
    let gasApproval: number = 0;
    let TradeInfo = {} as TradeInfo;
    let quote;

    TradeInfo = {
      ...initialTradeInfo,
      slippage: parseFloat(slippage || "0"),
      gasApproval: gasApproval,
    };

    // if tokens is empty ?
    if (tokens && tokens.length === 0) {
      return initialTradeInfo;
    } else {
      // get fees approval
      if (tokens !== undefined) {
        for (const token of tokens) {
          if (typeof token === "string") {
            // check if the type is a string, then do that thing
            try {
              const [tokenAddress, _, tokenBalance] = token.split("|");

              const tempGasApproval = await estimateGasForApproval(
                tokenAddress as string,
                address as string,
                provider
              );

              gasApproval += tempGasApproval;

              inputTokens.push({
                amount: tokenBalance,
                tokenAddress: tokenAddress,
              });
            } catch (error) {
              console.error(`Failed to process token: `, error);
            }
          } else if (typeof token === "object") {
            try {
              const tokenAddress = token.tokenAddress;
              const tokenBalance = token.amount;

              const tempGasApproval = await estimateGasForApproval(
                tokenAddress as string,
                address as string,
                provider
              );

              gasApproval += tempGasApproval;

              inputTokens.push({
                amount: tokenBalance,
                tokenAddress: tokenAddress,
              });
            } catch (error) {
              console.error(`Failed to process token: `, error);
              return { error: error}; 
            }
          } else {
            console.log("unknown token type");
          }
        }
      }
    }

    try {
      setLoading(true);
      const quoteRequestBody = {
        chainId: selectedChain.chainId,
        inputTokens: inputTokens,
        outputTokens: [
          {
            tokenAddress: "0x0000000000000000000000000000000000000000",
            proportion: 1,
          },
        ],
        slippageLimitPercent: 0.3,
        sourceBlacklist: [],
        pathVizImageConfig: {
          linkColors: ["#c73292", "#bd3299", "#af309f", "#9e30a9", "#932fb0"],
          nodeColor: "#5171f6",
          nodeTextColor: "#FFF",
          legendTextColor: "#C5CCD2",
          width: 1200,
          height: 400,
        },
        pathVizImage: true,
      };

      if (inputTokens.length !== 0) {
        const odosQuoteReq = await fetch("https://api.odos.xyz/sor/quote/v2", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(quoteRequestBody),
          signal,
        });
        console.log("Quote Request with odos API:", odosQuoteReq);
        setError(null);
        // if the request fails, throw an error

        if (false == odosQuoteReq.ok) {
          console.log("Error in Quote Request with odos API:", odosQuoteReq);          
          return { error : "Too much token selected or token not supported"};
        }
        const odosQuoteRes = await odosQuoteReq.json();

        if (odosQuoteReq.ok) {
          quote = odosQuoteRes;

          const gasSwap = await estimateGasForSwap(
            ethers.BigNumber.from(quote.gasEstimate)
          );

          TradeInfo = {
            ...TradeInfo,
            gasEstimateValue: gasSwap,
            outAmounts: quote.outValues[0],
          };
  
          if (tokens && tokens.length == 0) {
            return {
              tradeInfo: initialTradeInfo,
              odosPathViz: quote.pathVizImage,
            };
          } else {
            return { tradeInfo: TradeInfo, odosPathViz: quote.pathVizImage };
          }
        } else {
          console.error("Error in Quote Request with odos API:", odosQuoteReq);
        }
      }
    } catch (err) { 
      setErr(true);
      setError(err);
      console.error(`Failed to process token: `, err);
    } finally {
      setLoading(false);
    }
  };
  return { getTradeInfo, loading, err };
}

export const useEstimeGasForApproval = () => {
  const fetchTokenPrice = useFetchTokenPrice();
  const { selectedChain } = useContext(ChainContext);

  const estimateGasForApproval = async (
    tokenAddress: string,
    userAddress: string,
    provider: any
  ): Promise<any> => {
    // Create a new contract instance
    const erc20 = new Contract(tokenAddress, erc20Abi, provider);
    const SPENDER_ADDRESS =
      chainConfig.chains[selectedChain.chainId].spenderAddress;

    try {
      const estimate = await erc20.estimateGas.approve(
        SPENDER_ADDRESS,
        MaxAllowanceTransferAmount,
        { from: userAddress }
      );

      const formattedEstimate = ethers.utils.formatUnits(estimate, "gwei");
      /*     console.log(`Estimated Gas for Approval: ${formattedEstimate} Gwei`);
       */
      // Optionally calculate the cost in USD - ensure fetchTokenPrice is defined and works correctly
      const tokenPrice = await fetchTokenPrice(
        "0x0000000000000000000000000000000000000000"
      );

      const usdEstimate =
        parseFloat(formattedEstimate) * parseFloat(tokenPrice.price);

      return usdEstimate;
    } catch (error) {
      console.error("Error estimating gas:", error);
      return undefined; // Return undefined or handle error as needed
    }
  };

  // const estimateGasForSwap = async (tokenAddress: string) => {}

  return estimateGasForApproval;
};

export const useEstimeGasForSwap = () => {
  const fetchTokenPrice = useFetchTokenPrice();

  const estimateGasForSwap = async (gasEstimate: BigNumber) => {
    const formattedEstimate = ethers.utils.formatUnits(gasEstimate, "gwei");

    const tokenPrice = await fetchTokenPrice(
      "0x0000000000000000000000000000000000000000"
    );

    const usdEstimateForSwap =
      parseFloat(formattedEstimate) * parseFloat(tokenPrice.price);

    return usdEstimateForSwap;
  };

  return estimateGasForSwap;
};
