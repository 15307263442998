import { Flex, Spinner } from "@chakra-ui/react";

export const Spin = () => (
  <Flex w={"100vw"} h={"100vh"} justifyContent={"center"} alignItems={"center"}>
     <Spinner 
      thickness='1px'
      speed='1s'
      emptyColor='gray.200'
      color='black'
      />
  </Flex>
);
