import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";

import { StarIcon,ArrowRightIcon } from "@chakra-ui/icons";

import { Link, NavLink } from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { MdSettings } from 'react-icons/md'

export function Alertbar() {
  const bottomBorderColor = useColorModeValue("#000", "#CCC");

  return (
    <Box w='100%'
    bg={"#f3f6fc"} 
    minH={"3vh"}
    p={3}
    >
      <Center>
       <StarIcon w={3} h={3} color='yellow.300' marginRight={5} />
        <Text fontSize={14} color={'#37befc'}>Start earning with CLEANSWAP now. A <b>July 1st</b> snapshot will qualify active users for potential airdrops and rewards.</Text>
        <ArrowRightIcon w={3} h={3} color='yellow.300' marginLeft={5} />
        {/* <ColorModeSwitcher /> */}
      </Center>
    </Box>
  );
}

const activeStyle = {
  fontWeight: "bold",
  fontSize: "0.5em",
};

const inactiveStyle = {
  fontSize: "0.5em",
};




