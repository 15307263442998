import * as React from 'react'
// import { type PublicClient, usePublicClient, type WalletClient, useWalletClient  } from 'wagmi'
// import { type HttpTransport } from 'viem'

//new
import {useMemo} from 'react'
import { type Config, getClient, getConnectorClient } from "@wagmi/core";
import type { Client, Chain, Transport, Account } from "viem";
import { providers } from "ethers";
import { useConnectorClient, useClient } from "wagmi";


export function clientToProvider(client: Client<Transport, Chain>) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === "fallback")
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<Transport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    );
  return new providers.JsonRpcProvider(transport.url, network);
}

// export function getEthersProvider(
//   config: Config,
//   { chainId }: { chainId?: number } = {}
// ) {
//   const client = getClient(config, { chainId }) || undefined;
//   if (client) {
//     return clientToProvider(client);
//   } else {
//     console.log("error in getEthersProvider");
//   }
// }

// /** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const client = useClient<Config>({ chainId });
  return useMemo(
    () => (!client ? undefined : clientToProvider(client)),
    [client]
  );
}

//new
export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

// /** Action to convert a Viem Client to an ethers.js Signer. */
// export async function getEthersSigner(
//   config: Config,
//   { chainId }: { chainId?: number } = {}
// ) {
//   const client = await getConnectorClient(config, { chainId });
//   return clientToSigner(client);
// }

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId });
  return useMemo(
    () => (client ? clientToSigner(client) : undefined),
    [client]
  );
}


// //old
// export function publicClientToProvider(publicClient: PublicClient) {
//   const { chain, transport } = publicClient
//   const network = {
//     chainId: chain.id,
//     name: chain.name,
//     ensAddress: chain.contracts?.ensRegistry?.address,
//   }
//   if (transport.type === 'fallback')
//     return new providers.FallbackProvider(
//       (transport.transports as ReturnType<HttpTransport>[]).map(
//         ({ value }) => new providers.JsonRpcProvider(value?.url, network),
//       ),
//     )
//   return new providers.JsonRpcProvider(transport.url, network)
// }

// /** Hook to convert a viem Public Client to an ethers.js Provider. */
// export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
//   const publicClient = usePublicClient({ chainId })
//   return React.useMemo(() => publicClientToProvider(publicClient), [publicClient])
// }

// //old
// export function walletClientToSigner(walletClient: WalletClient) {
//   const { account, chain, transport } = walletClient
//   const network = {
//     chainId: chain.id,
//     name: chain.name,
//     ensAddress: chain.contracts?.ensRegistry?.address,
//   }
//   const provider = new providers.Web3Provider(transport, network)
//   const signer = provider.getSigner(account.address)
//   return signer
// }

// /** Hook to convert a viem Wallet Client to an ethers.js Signer. */
// export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
//   const { data: walletClient } = useWalletClient({ chainId })
//   return React.useMemo(
//     () => (walletClient ? walletClientToSigner(walletClient) : undefined),
//     [walletClient],
//   )
// }
