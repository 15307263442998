import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Heading,
    VStack,
    Text,
  } from "@chakra-ui/react";


export function Faq() {
  
    return(
        <VStack mb={20}>
            <Heading as='h2' w={'100%'} size='md' mt='5em'>FAQ</Heading>
            <Accordion  w={'100%'} allowToggle>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                            What is CleanSwap?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Text fontSize='md'>CleanSwap is a service that allows converting small cryptocurrency amounts into native gas tokens of the current blockchain with a single transaction, simplifying portfolio management.</Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                        How do I use CleanSwap to clean my wallet?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                    <Text fontSize='md'>Simply connect your wallet to CleanSwap, 
                    select the "dust" tokens to clean, 
                    and if those tokens have not yet been approved in your wallet application 
                    like MetaMask, approve them. 
                    Once approved, you can perform a single clean transaction to convert them into gas tokens.</Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                        Why should I use CleanSwap?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                    <Text fontSize='md'>CleanSwap offers a dual benefit for users. 
                    First, by consolidating multiple token cleanings into one transaction, 
                    it significantly reduces the gas fees compared to individual transactions
                     for each type of "dust". Second, it maximizes your savings by intelligently
                      calculating the most cost-effective routes for converting your digital dust. 
                      This combination of reducing transaction costs and optimizing conversion rates makes 
                      CleanSwap an unparalleled tool for efficient asset management.</Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                        Where can I get help or provide feedback?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                    <Text fontSize='md'>For assistance or to share your experiences, 
                    join our Telegram group. Our community and team are on hand to provide 
                    support and take on board your feedback.</Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </VStack>
    )
}
  
  
  

