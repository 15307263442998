import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import {useState, useEffect} from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import { RouterProvider } from "react-router-dom";
import router from "./Router";

import { ChainContext } from "./context/ChainContext";

import chainConfig from './config'

import "@rainbow-me/rainbowkit/styles.css";
import {
  // getDefaultWallets,
  getDefaultConfig,
  lightTheme,
  midnightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";

import { useAccount } from 'wagmi' 

//new
import { WagmiProvider, http } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import {
  bsc,
  base,
  mainnet,
  arbitrum,
  polygon,
  avalanche
} from "wagmi/chains";

import { Spin } from "./components";
import theme from "./theme";

const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: '37a1ee8cab4dec8b64af48eb3dc77834',
  chains: [bsc, base, mainnet, arbitrum, polygon, avalanche], // Add the required elements at position 0
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: http(),
    [arbitrum.id]: http(),
    [polygon.id]: http(),
    [avalanche.id]: http(),
  },
})

const queryClient = new QueryClient()

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const initialChain = bsc; 

const App = () => {
  const { colorMode } = useColorMode();
  const [selectedChain, setSelectedChain] = useState(() => {
    const savedState = localStorage.getItem('selectedChain');
    if (savedState) {
      return JSON.parse(savedState);
    }
    return { chainId: 56, chainName: "bsc" };
  });

  const { chain } = useAccount()

  useEffect (() => {
    if (chain) {
      let chainId = chain.id ?? 0;
      let chainName = chainConfig.chains[chainId].network;
      
      const newChain = { chainId, chainName };
      setSelectedChain(newChain);
      localStorage.setItem('selectedChain', JSON.stringify(newChain));
    }
  }, [chain]);

  return (
    <RainbowKitProvider
      theme={
        colorMode === "light"
          ? lightTheme({ borderRadius: "small" })
          : midnightTheme({ borderRadius: "small" })
      }
      initialChain={initialChain} 
    >
      <ChainContext.Provider value={{ selectedChain, setSelectedChain }}>
        <RouterProvider router={router} fallbackElement={<Spin />} />
      </ChainContext.Provider>
    </RainbowKitProvider> 
  );
};

root.render(
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>

    <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
      </QueryClientProvider>
    </WagmiProvider>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
