import { ChainContext } from "../context/ChainContext";
import { useContext } from "react";
import chainConfig from "../config";



export const useFetchTokenPrice = () => {
  const { selectedChain } = useContext(ChainContext);
  const fetchTokenPrice = async (tokenAddress: string): Promise<any> => {
    const tokenPriceBaseUrl = "https://api.odos.xyz/pricing/token";

    const chainId = selectedChain.chainId;
    const response = await fetch(
      `${tokenPriceBaseUrl}/${chainId}/${tokenAddress}`
    );

    if (response.status === 200) {
      const tokenPrice = await response.json();
      // handle token price data
      return tokenPrice;
    } else {
      console.error("Error in Transaction Assembly:", response);
      // handle token price failure cases
      return null;
    }
  };
  return fetchTokenPrice;
};

export const useGetTokenInfo = () => {
  const { selectedChain } = useContext(ChainContext);
  const getTokenApproval = useGetTokenApproval();

  const getTokenInfo = async (address: string, spender: string) => {
    const chainId = selectedChain.chainId;
    const minimuDustInUSD = chainConfig.chains[chainId].minimumDustInUSD;

    try {
      const optionsMoralis = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-Key": process.env.REACT_APP_MORALIS_API_KEY as string,
        },
      };

      const optionOdos = {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      };

      /* GET ODOS SUPPORTED TOKENS https://docs.odos.xyz/api/endpoints/  */
      const reponseOdos = await fetch(
        `https://api.odos.xyz/info/tokens/${chainId}`,
        optionOdos
      );

      const url = `https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=${selectedChain.chainName}&exclude_spam=true&exclude_unverified_contracts=false&exclude_native=true`
      
      /* Get connected wallet token list */
      const responseMoralis = await fetch(
        url,
        optionsMoralis
      );
      
      const jsonOdos = await reponseOdos.json();
      const walletTokenListJson = await responseMoralis.json();

      /* FILL new table with lowercase value La liste Odos n'est plus utilisée ici */ 
      let odosTokenMap = jsonOdos.tokenMap;
      let odosTokenSet = new Set();
      for (let key in odosTokenMap) {
        odosTokenSet.add(key.toLowerCase());
      }
      
      //console.log("before");
      //console.log(walletTokenListJson.result)
      /* New method before filtering replacer 0,undefined, null usd_value by oddos */
      for (let token in walletTokenListJson.result){
        
          /* https://docs.odos.xyz/api/endpoints/  */
          const callOdosForPrice = await fetch(
            `https://api.odos.xyz/pricing/token/${chainId}/${walletTokenListJson.result[token].token_address}`,
            optionOdos
          );

          const responseOdosForPrice = await callOdosForPrice.json();
          
          if (callOdosForPrice.status === 200) {
            const odosTokenPrice = responseOdosForPrice.price;
            // rewrite moralis response with odos pricing answers
            walletTokenListJson.result[token].usd_price = odosTokenPrice;
            walletTokenListJson.result[token].usd_value = odosTokenPrice * walletTokenListJson.result[token].balance_formatted;
            
            
            //console.log("rewrite");
            //console.log(responseOdosForPrice.price);
          } else {
            walletTokenListJson.result[token].usd_price = 0;
            walletTokenListJson.result[token].usd_value = null;
            //console.error("sorry no price for token:", walletTokenListJson.result[token].name);
            // handle odos token price failure cases
          }
          
      }
      
      //console.log('after')
      console.log(walletTokenListJson.result);

      /* FILTRE liste moralis */
      const filteredWalletTokenListJson = walletTokenListJson.result.filter(
        (t: any) => t.usd_value !== null && t.usd_value !== undefined /*&& t.usd_value != 0*/
      );

      for (let token of filteredWalletTokenListJson) {
        const approval = await getTokenApproval(token.token_address,address,spender);

        token["allowance"] = approval.allowance;
        if (odosTokenSet.has(token.token_address.toLowerCase()) == false) {
          token["isListed"] = false;
        } else {
          token["isListed"] = true;
        }
      }
      
      
      /* Filter : remove < min value  */
      const underMinfilteredWalletTokenListJson = filteredWalletTokenListJson.filter((t: any) => {

        //alert(t.usd_value.toFixed(2));
        return (
          t.usd_value >=  0 && // important, don't put toFixed, use gross value and only display > 0
          t.usd_value.toFixed(10) <= minimuDustInUSD
        );
      });
      console.log(underMinfilteredWalletTokenListJson);

      return [underMinfilteredWalletTokenListJson, null];
    } catch (err) {
      console.log(err);
      return [[], err];
    }
  };

  return getTokenInfo;
};

export const useGetTokenApproval = () => {
  const { selectedChain } = useContext(ChainContext);

  const getTokenApproval = async (tokenAddress:any, address: any, spender: string) => {
    const approval = await fetch(
      `https://deep-index.moralis.io/api/v2.2/erc20/${tokenAddress}/allowance?chain=${selectedChain.chainName}&owner_address=${address}&spender_address=${spender}`,
      {
        headers: {
          "X-API-Key": process.env.REACT_APP_MORALIS_API_KEY as string,
        },
      }
    );
    const approvalJSON = await approval.json();
/*     console.log(approvalJSON)
 */    return approvalJSON;
  }
  return getTokenApproval;
}