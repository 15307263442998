import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  VStack,
  Stack,
  Select,
  FormControl,
  FormLabel,
  Heading,
  Skeleton,
} from "@chakra-ui/react";
import { ChainContext } from "../context/ChainContext";
import { OdosPathViz } from "./OdosPath";
import { initialTradeInfo } from "../hooks/getTradeInfo";

import chainConfig from "../config";

interface AccordionAnalyticsProps {
  slippage: string;
  onSlippageChange: (value: string) => void;
  tradeInfo: any; // Consider defining a more specific type for tradeInfo if possible
  tokenNumber: number;
  value: any;
  address: any;
  provider: any;
  loading: boolean;
  tradeInfoOdosPath: any;
  error: boolean;
}

export const AccordionAnalytics: React.FC<AccordionAnalyticsProps> = ({
  slippage,
  onSlippageChange,
  tradeInfo,
  tokenNumber,
  value,
  address,
  provider,
  loading,
  tradeInfoOdosPath,
  error,
}) => {
  const gasEstimateValue =
    tradeInfo?.gasEstimateValue || initialTradeInfo.gasEstimateValue;
  const gasApproval = tradeInfo?.gasApproval || initialTradeInfo.gasApproval;
  const outAmounts =
    parseFloat(tradeInfo?.outAmounts) || initialTradeInfo.outAmounts;

  let priceNormal = gasApproval + tokenNumber * gasEstimateValue;
  let priceCleanswap = gasApproval + gasEstimateValue;
  let priceDifference = priceNormal - priceCleanswap;
  const { selectedChain } = useContext(ChainContext);

  const symbolChain = chainConfig.chains[selectedChain.chainId].symbol;

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontSize="sm">
            <Heading size="sm">Transaction Details</Heading>
            {loading 
             ? (
              <Skeleton height="20px" borderRadius={"15px"} m={2} />
            ) : (
              <>
                {value.length === 0 ? (
                  <Text>Output Value: ~$0.00 in {symbolChain} </Text>
                ) : (
                  <Text>Output Value: ~${outAmounts.toFixed(2)}</Text>
                )}
                {value.length === 0 ? (
                  <Text>Cleanswap Gas estimate: ~$0.00</Text>
                ) : (
                  <Text>
                    Cleanswap Gas estimate: ~${gasEstimateValue.toFixed(2)}
                  </Text>
                )}
                {value.length === 0 ? (
                  <Text>Cleanswap gas saved : ~$0.00</Text>
                ) : (
                  <Text>
                    Cleanswap gas saved : ~${priceDifference.toFixed(2)}
                  </Text>
                )}
              </>
            )}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4} fontSize="sm">
          <VStack align="stretch" spacing={2} textAlign="left">
            <FormControl>
              <FormLabel htmlFor="slippage">
                <Heading size={"s"}>Slippage Tolerance</Heading>
              </FormLabel>
              <Select
                id="slippage"
                size="sm"
                value={slippage}
                onChange={(e) => onSlippageChange(e.target.value)}
              >
                <option value="0.5">0.5%</option>
                <option value="1">1%</option>
                <option value="2">2%</option>
                <option value="3">3%</option>
              </Select>
            </FormControl>
            <Text>
              <Heading size={"s"}>Trade Type:</Heading>Volatile
            </Text>
            <Box
              flex={7}
              p={3}
              w={{ base: "100%", md: "100%", lg: "100%" }}
              display={"block"}
              justifyContent={"center"}
            >
              <Stack spacing={2} my={3}>
                <Box
                  w={"full"}
                  justifyContent={"center"}
                  display="flex"
                  alignItems={"center"}
                >
                  <OdosPathViz
                    address={address}
                    slippage={slippage}
                    provider={provider}
                    loading={loading}
                    tradeInfoOdosPath={tradeInfoOdosPath}
                  />
                </Box>
              </Stack>
            </Box>

            {/* You can render more information from tradeInfo here */}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
