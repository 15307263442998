import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { FaTelegram, FaTwitter, FaFile } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Footer = () => {
  const bottomTopColor = useColorModeValue("#dfdfdf", "#1e1e1e");

  return (
    <Box>
      <Flex
        bg={"white"}
        borderTopWidth={"0.01em"}
        borderTopColor={bottomTopColor}
        minH={"9vh"}
        px={{ base: 4, md: "3%" }}
        align={"center"}
        justifyContent={"center"}
        gap={10}
      >
        {/* TODO: CHANGE THESE LINKS */}
        <Link to={"https://t.me/clean_swap"} target={"_blank"}>
          <FaTelegram />
        </Link>
        <Link to={"https://twitter.com/Clean_Swap/"} target={"_blank"}>
          <FaTwitter />
        </Link>
        <Link
          to={
            "https://docs.google.com/document/d/1zTB5WQYtV0GtxDXFsfvbXFCt5fTLuYW-GEuaITlC9sc/edit#heading=h.z6ne0og04bp5"
          }
          target={"_blank"}
        >
          <FaFile />
        </Link>
      </Flex>
    </Box>
  );
};
